.main-container {
  text-align: center;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  scroll-behavior: smooth;
  color: #636363;
  overflow-y: hidden;
}

form {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}


/* WELCOME SECTION */
.welcome-container {
  font-size: calc(10px + 2vmin);
  color: #636363;
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  background-color: #78b8cb;
  position: relative;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: whitesmoke;
  font-size: large;
}

.sub-heading {
  margin: 0 0 20px 0;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: whitesmoke;
  font-size: 2.7rem;
}

.tagline {
  margin: 20px 0 20px 0;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: whitesmoke;
  font-size: 2rem;
}
#form-submitted {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.s3-header, .s3-icon3, h3, h4 {
  margin: 10px 0;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.s3-icon3 {
  max-width: 300px;
  height: auto;
  display: block;
}

.s3-icon3.small-icon {
  width: 100px;
  height: auto;
  position: absolute;
  bottom: -7rem;
  right: -1rem;
}

.about-container {
  background-image: url('/public/img/about_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 1rem;
}

.about-content {
  margin: 0 auto;
  font-size: 18px;
  text-align: left;
  width: 90%;
}

#about-subtitle, #about-header {
  text-align: center;
  font-family: "Madimi One", sans-serif;
  color: white;
  margin: 20px 0 20px 0;
  font-size: 1.5rem;
}

/* Media query for smaller screens (adjust breakpoint as needed) */
@media (max-width: 768px) {
  #about-subtitle {
    left: 2rem;
  }
}

/* Media query for smaller screens (adjust breakpoint as needed) */
@media (max-width: 768px) {
  #about-section p  {
    width: 90%; /* Increase width for smaller screens */
    margin: 10px auto; /* Reduce margins for smaller screens */
  }
}


/* Action Section Styling */

#action-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #78b8cb;
  text-align: center;
  padding: 25px;
}

.navigation-buttons {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 50px;
}

.navigation-buttons button {
  padding: 2% 2%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #bd831f; /* Yellow background */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-transform: uppercase;
}

.navigation-buttons button:hover {
  background-color: #fbc02d; /* Slightly darker yellow on hover */
  transform: scale(1.05);
}

.navigation-buttons button:focus {
  outline: none;
}

#share-button {
  background-color: #bd831f; /* Yellow background */
}

#explore-button {
  background-color: #78b8cb;
}



/* Form Styling */

.form-section {
  margin: 0 5%;
  padding: 2rem 0;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* Landscape Mode Adjustments */
@media only screen and (orientation: landscape) {
  .form-section {
    height: auto; /* Allow content to fit within the container */
    max-height: 100vh; /* Prevent overflowing in landscape mode */
    padding: 2rem 2%;
  }
}

#map-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #bd831f;
  text-align: center;
  height: 100vh;
}

.map-text-container {
  margin: auto 2rem 0px 2rem;
}

#location-time-container {
  background-color: #78b8cb;
}

.section-header {
  font-size: 30px;
  font-weight: bold;
  color: white;
  margin: 2rem 0 1rem 0px;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.center {
  text-align: center;
}

form {
  margin-top: 20px;
}

.form-subheader {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin: 20px 0;
  text-align: center;
}

/* Ensure the container for sections uses flexbox for layout */
.form-container {
  display: flex;
  flex-direction: column;
  /* gap: 20px; adds spacing between sections  */
  background-color:  #bd831f;
}

.form-inputs {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Ensure inputs take full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.form-content {
  font-size: 20px;
  color: white;
  margin: 0;
}


.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  width: 100%; /* Make sure it takes the full width of the container */
  max-width: 400px; /* Set a maximum width for the form fields */
  margin: 0 auto; /* Center the form-group container */
}

.form-labels {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  text-align: left;
  margin: 15px 0 10px 0;
  width: 100%; /* Ensure labels take full width */
}

.review-label.form-labels.primary-label {
  font-size: 18px;
  margin-top: 20px;
}

.review-label.form-labels.secondary-label {
  font-size: 16px;
  margin-top: 0;
}

#location-date-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container horizontally */
  gap: 20px; /* Add some gap between form-group divs */
  width: 100%;
}

.list-container {
  margin: 3px;
}

.list-container .info-text {
  text-align: left;
}

.info-text {
  font-size: 20px;
  color: white;
  text-align: center;
  margin: 5px 0 3rem 0;
}

.list-container .map-instructions {
  text-align: left;
}


label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="datetime-local"] {
  padding: 10px;
  margin-bottom: 20px;
  width: 90%;
  max-width: 400px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Emotions Form */

#emotions-container {
  background-color: #bd831f;
}

#safety-container {
  background-color: #78b8cb;
}

#belonging-container {
  background-color: #bd831f;
}

.warning {
  color: #AE3F3F;
  font-size: 20px;
  font-weight: bold;
}

.slider-section {
  margin: 1rem 0 3rem 0;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: white;
  font-size: 16px;
}

.slider-labels span {
  padding: 5px;
}

.emoji-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.emoji {
  font-size: 20px;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 10px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  border-radius: 15px;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Handle height */
  height: 30px; /* Handle width */
  background: #FF4500; /* Orange color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Round handle */
  transition: transform 0.2s, background-color 0.2s;
}

.slider::-webkit-slider-thumb:hover {
  transform: scale(1.5); /* Grow on hover */
  background-color: #FFD700; /* Darker blue on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #FF4500;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.2s, background-color 0.2s;
}

.slider::-moz-range-thumb:hover {
  transform: scale(1.5);
  background-color: #FFD700;
}

.slider::-ms-thumb {
  width: 25px;
  height: 25px;
  background: #FF4500;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.2s, background-color 0.2s;
}

.slider::-ms-thumb:hover {
  transform: scale(1.5);
  background-color: #FFD700;
}


/* IDENTITY STYLING */

#identity-container {
  background-color: #78b8cb;
  padding: 20px; /* Padding to provide space inside the container */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  height: auto;
}

/* Ensure the container can grow with content */
#identity-section {
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  gap: 20px; /* Space between child elements */
  padding: 20px;
  box-sizing: border-box;
}

#identity-section .hint {
  font-style: italic;
  color: black;
}

#identity-section .identity-option-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.identity-option-button-group {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 50px;
  color: #4a90e2;
}

.option-button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #4a90e2;
  background-color: #ffd813;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-transform: uppercase;
  align-self: center; /* Center the submit button horizontally */
  width: 15em;
}

.option-button:hover {
  background-color: #fbc02d;
  transform: scale(1.05);
}

.option-button:focus {
  outline: none;
}

.option-button:active {
  background-color: #e6ac00;
}

.option-button.selected {
  background-color: #fbc02d;
  transform: scale(1.05);
  color: #ffffff;
}

/* Elements added dynamically, with consistent spacing */
.mb-3 {
  margin-bottom: 20px; /* Ensure space between dynamically added elements */
  width: 100%; /* Full width to ensure alignment within the section */
}

/* Responsive adjustments specifically for mobile devices */
@media (max-width: 768px) {
  #identity-container {
    padding: 10px; /* Smaller padding for smaller screens */
  }
  #identity-section {
    padding: 10px;
    gap: 10px; /* Reduce gap between elements on smaller screens */
  }
  .mb-3 {
    margin-bottom: 10px; /* Smaller margin for mobile screens */
  }
}

/* Final thoughts styling */

/* Styling specific to the final-thoughts section */
#final-thoughts-section .hint {
  font-style: italic;
  color: black;
}

#final-thoughts-container {
  background-color: #bd831f;
}

#final-thoughts-section {
  margin: 0 2rem;
  padding: 0 20px;
  text-align: center;
  min-height: 100vh;  /* Ensures the section is at least as tall as the viewport */
}

#final-thoughts-section textarea {
  width: 90%;
  height: 150px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}


/* Responsive design to maintain layout integrity */
@media (max-width: 768px) {
  #final-thoughts-section {
    margin: 1rem;
    padding: 2.5rem;
  }

  #final-thoughts-section textarea {
    width: 100%;
    height: 120px;
  }
}



/* REVIEW FORM SECTION */

#review-container {
  background-color: #78b8cb;
  width: 100%;
}

#review-section {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
}

.review-form {
  text-align: left;
  padding: 20px;
  width: 70%;
}

@media (max-width: 768px) {
  .review-form  {
    width: 90%;
    margin: 10px auto;
  }
}

@media (orientation: landscape) {
  #review-section {
    margin: 20px auto;
    width: 90%;
  }

  .review-form {
    width: 100%;
    max-width: 1200px;
  }
}

.review-form-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.review-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.review-form .form-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.review-form label {
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%; /* Ensure labels take full width */
  text-align: left; /* Align text to the left */
}

.review-form input,
.review-form textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Ensure inputs take full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.review-form .range-description {
  display: block;
  font-size: 0.9em;
  color: lightsteelblue;
}

.review-form .review-label {
  color: white;
  width: 100%; /* Ensure labels take full width */
  text-align: left;
}

.review-form .input-group textarea {
  resize: vertical;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.submit-button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #4a90e2;
  background-color: #ffd813;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-transform: uppercase;
  align-self: center; /* Center the submit button horizontally */
}

.submit-button:hover {
  background-color: #fbc02d;
  transform: scale(1.05);
}

.submit-button:focus {
  outline: none;
}

.submit-button:active {
  background-color: #e6ac00;
}

/* Explore Map Styling */

.explore-container {
  position: relative; /* Contain absolutely positioned elements */
  width: 100%;
  height: auto; /* Allow the container to grow based on its content */
  overflow: hidden; /* Prevent overflow issues */
}

.explore-section {
  height: auto; /* Adjust to ensure it fits within the parent */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #bd831f;
  color: white;
  text-align: center;
  padding: 20px;
}

.legend-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.legend-title, .legend-items {
  color: #bd831f;
}

.mapboxgl-popup-content {
  color: #bd831f;
}

.map-popup-text {
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #636363;
  padding: 0 20% 5%;
  font-size: 16px;
}

.map-button-group {
  display: flex; /* Use flexbox for easy horizontal alignment */
  justify-content: space-around; /* Distribute buttons with space between */
}

.map-option-button {
  padding: 10px 25px;
  font-size: 12px;
  font-weight: bold;
  color: #4a90e2;
  background-color: #ffd813;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-transform: uppercase;
}

.map-option-button:hover {
  background-color: #fbc02d;
  transform: scale(1.05);
}

.map-option-button:focus {
  outline: none;
}

.map-option-button:active {
  background-color: #e6ac00;
}

.map-option-button.selected {
  background-color: #fbc02d;
  transform: scale(1.05);
  color: #ffffff;
}

/* Reset styles specifically for the Mapbox Geocoder input */
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
  border: none;
  border-radius: 0;
  font-size: inherit;
  margin-bottom: 0;
  max-width: none;
  padding: 0;
  width: auto;
}

.mapboxgl-ctrl-geocoder input[type="text"] {
  border: none;
  border-radius: 0;
  font-size: inherit;
  margin-bottom: 0;
  max-width: none;
  padding: 0;
  width: auto;
}
